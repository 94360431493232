// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\PC-01\\WebstormProjects\\corporate-site\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-projects-js": () => import("C:\\Users\\PC-01\\WebstormProjects\\corporate-site\\src\\templates\\projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\PC-01\\WebstormProjects\\corporate-site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\PC-01\\WebstormProjects\\corporate-site\\.cache\\data.json")

