module.exports = [{
      plugin: require('C:/Users/PC-01/WebstormProjects/corporate-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/PC-01/WebstormProjects/corporate-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/Users/PC-01/WebstormProjects/corporate-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
